body{
    color: #1976d2 ;
    background-color: #1976d2
}

h1{
    color:white
}

a{
    color: white;
    font-size: 30px;
}